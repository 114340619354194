import React from "react";
import { GlobalContextProvider } from "./src/context/GlobalContext";

// Fontsource
import "@fontsource/varela";
import "@fontsource/montserrat";
import "@fontsource/roboto";

// Bootstrap
import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

// Import bootsrap scss
import "./src/scss/main.scss"


export const wrapRootElement = ({ element }) => (
  // We can wrap each page here
  <GlobalContextProvider>{element}</GlobalContextProvider>
);