import React from "react";
import { graphql, useStaticQuery } from "gatsby";


const GlobalContext = React.createContext({});


export const GlobalContextProvider = ({ children }) => {
  const query = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
    }
  `);

  return (
    <GlobalContext.Provider
      value={{
        query,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;